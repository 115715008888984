// Sandstone 4.1.3
// Bootswatch

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #325D88 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d9534f !default;
$orange: #F47C3C !default;
$yellow: #ffc107 !default;
$green: #93C54B !default;
$teal: #20c997 !default;
$cyan: #29ABE0 !default;

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $gray-800 !default;

$yiq-contrasted-threshold: 170 !default;


// custom variables
$topbar-height: 68px;
$topbar-bg: #1B1E24;
$topbar-fg: #8b91a0;

$sidebar-width: 225px; //48px;
$sidebar-width-toggled: 225px;
$sidebar-item-width: $sidebar-width - 8px;
$sidebar-item-width-toggled: $sidebar-width-toggled - 8px;
$sidebar-icon-width: 48px;
$sidebar-bg: #1B1E24;
$sidebar-fg: #8b91a0;
$sidebar-fg-active: #EABF2B;
$sidebar-border-color: #393F4C;

$footer-height: 70px;

$message-title-bg: #E2E6E9;
$message-title-icon-fg: #1B1E24;

//$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px );
$grid-breakpoints: ( xs: 0, lg: 1024px, xl: 1200px );

//$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px );
$container-max-widths: ( lg: 960px, xl: 1140px );

$input-btn-padding-y: .25rem !default;
$input-btn-padding-x: .5rem !default;
/*$input-btn-padding-y-sm: .25rem !default;
$input-btn-padding-x-sm: .5rem !default;
$input-btn-padding-y-lg: .5rem !default;
$input-btn-padding-x-lg: 1rem !default;*/

$form-group-margin-bottom: .5rem !default;

// bootstrap source
@import "../node_modules/bootstrap/scss/bootstrap.scss";

.d-none-empty:empty {
    display: none;
}

.clickable {
    cursor: pointer;
}

// custom styles
.app {
    min-height: 100vh;
    position: relative;
    padding-bottom: $footer-height;

    &.app-topbar-fixed-top {
        padding-top: $topbar-height;
    }

    &-modal {
        background-color: $sidebar-bg;
    }
}

.app-topbar {
    background-color: $topbar-bg;
    margin-left: $sidebar-width;
    border-left: 1px solid $sidebar-border-color;

    .group {
        border-left: 1px solid $topbar-fg;
        padding-left: .5rem;
        padding-right: .5rem;
    }

    .group-right {
        border-right: 1px solid $topbar-fg;
        padding-left: .5rem;
        padding-right: .5rem;
    }

    a {
        color: $topbar-fg;
        transition: all 0.3s;

        &:hover {
            color: $light;
        }
    }

    .event {
        color: $light;
        padding: .25rem 1rem .25rem 1rem;

        .title {
            font-size: 1.5rem;
        }

        .separator {
            color: #9FABB8;
        }
    }

    .calculator {
        padding-left: .5rem;
        padding-right: .5rem;
        color: $topbar-fg;

        input {
            color: $light;
            display: inline;
            width: auto !important;
            min-width: 50px;
            padding-left: 0;
            padding-right: 0;
            border-color: transparent;
            background-color: transparent;
            text-align: right;

            &:hover, &:focus {
                outline: 0;
            }
        }

        .output {
            color: $light;
            padding-left: .125rem;
            font-weight: bold;
            color: #EABF2B;

            &.error {
                color: $danger;
            }
        }
    }
}

.app-body {
    min-height: calc(100vh - #{$footer-height});
    position: relative;

    .content {
        margin-left: $sidebar-width;
        transition: all 0.3s;
    }
}

.app-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 1rem 1rem 1rem;
    transition: all 0.3s ease;

    a {
        color: $body-color;
    }

    &.app-footer-light {
        color: $light;

        a {
            color: $light;
        }
    }
}

.btn-scroll-up {
    visibility: collapse;
    opacity: 0;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: $zindex-fixed + 1;
    transition: all 0.3s ease;
}

body.show-scroll-up {
    .app-footer {
        padding-right: 5rem;
    }

    .btn-scroll-up {
        visibility: visible;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }
}


.sidebar {
    position: fixed;
    z-index: $zindex-fixed;
    width: $sidebar-width;
    top: 0;
    bottom: 0;
    background-color: $sidebar-bg;

    &-scroll {
        position: relative;

        & > .nav {
            display: block;
            z-index: $zindex-fixed + 1;
        }
    }

    .nav {

        & > .nav-item {
            width: $sidebar-item-width;
            white-space: nowrap;
            overflow: hidden;
            transition: all 0.3s;

            .nav-link {
                padding: 0;
                display: flex;
                flex-direction: row;
                width: $sidebar-item-width-toggled;

                .nav-icon {
                    display: block;
                    padding: .5rem;
                    width: $sidebar-icon-width;
                    color: $sidebar-fg;
                    transition: all 0.3s;
                }

                .nav-text {
                    display: block;
                    padding: .25rem;
                    color: $sidebar-fg;
                    background-color: $sidebar-bg;
                    transition: all 0.3s;
                }
            }

            .nav-badge {
                margin: 0 .5rem 0 1rem;
            }

            > .nav {
                margin-left: $sidebar-icon-width;
            }

            &.active {
                & > .nav-link {
                    .nav-icon {
                        /*background-color: $sidebar-bg-active;*/
                        color: $sidebar-fg-active;
                    }

                    .nav-text {
                        /*background-color: $sidebar-bg-active-secondary;*/
                        color: $sidebar-fg-active;
                    }
                }
            }

            &:hover {
                width: $sidebar-item-width-toggled;

                & > .nav-link {
                    .nav-icon, .nav-text {
                        color: $light;
                    }
                }
            }
        }
    }

    hr {
        border-top: 1px solid $sidebar-fg;
    }
}

.message {
    &-title {
        background-color: $message-title-bg;

        a {
            color: #343a40;
        }

        .group {
            border-left: 1px solid #8b91a0;
            padding-left: .5rem;
            padding-right: .5rem;
        }

        .btn-link {
            color: $link-color;
            font-weight: bold;
            padding: 0 .5rem;
            transition: all 0.3s;

            &:hover {
                color: $link-hover-color;
                text-decoration: none;
            }
        }

        .icon {
            color: $message-title-icon-fg;
        }

        .dt {
            font-size: .8rem;
        }

        .dropdown-toggle::after {
            display: none;
        }
    }

    .select-case {
        .select2-container {
            min-width: 250px;
            width: auto;

            .select2-selection--single {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-color: #343a40;
            }
        }

        &.input-group-sm {
            font-size: $font-size-sm;
        }

        .select2-container--default .select2-selection--single .select2-selection__rendered {
            line-height: $line-height-base;
            padding: $input-padding-y-sm $input-padding-x-sm;
        }

        .select2-container .select2-selection--single {
            height: auto;
        }
    }

    .btn-toolbar .btn {
        padding-top: .125rem;
        padding-bottom: .125rem;
    }

    &.message-unread {
        .message-title {
            background-color: #F4DE94;

            .icon a, .subject a {
                color: black;
            }
        }
    }
}

/* dotvvm business pack modifications */
.dotvvm-bp-date-time-picker:not(.dotvvm-bp-flag_inline),
.dotvvm-bp-date-picker:not(.dotvvm-bp-flag_inline) {
    @include border-radius();

    a:last-of-type {
        @include border-right-radius($border-radius);
    }

    & > .dotvvm-bp-primitive_text-box {
        font-size: 1em;
        padding: $input-padding-y $input-padding-x;
        @include border-left-radius($border-radius);
    }
}
// add table in card support
.card {
    > .table {
        border-right-width: 0;
        border-bottom-width: 0;
        border-left-width: 0;
        border-top-width: 0;
        margin-bottom: 0;

        td:first-child,
        th:first-child {
            border-left-width: 0;
        }

        td:last-child,
        th:last-child {
            border-right-width: 0;
        }

        tr:first-child td,
        tr:first-child th {
            border-top-width: 0;
        }

        tr:last-child td {
            border-bottom-width: 0;
        }
    }

    > .card-body ~ .table {
        tr:first-child td,
        tr:first-child th {
            border-top-width: 2px;
        }
    }
}

.form-sm {
    .form-group {
        margin-bottom: .25rem;
    }
}

.table-sortable {
    > thead > tr > th {
        position: relative;

        .fa-sort-up, .fa-sort-down {
            position: absolute;
            right: 12px;
            color: $gray-500;

            &.active {
                color: $black;
            }
        }

        .fa-sort-up {
            top: 15px;
        }

        .fa-sort-down {
            bottom: 15px;
        }
    }

    &.table-sm {
        .fa-sort-up {
            top: 8px;
        }

        .fa-sort-down {
            bottom: 8px;
        }
    }
}

.table.table-thead-light {
    thead {
        th {
            color: $table-head-color;
            background-color: $table-head-bg;
            border-color: $table-border-color;
        }
    }
}

.pager {
    display: flex;
    @include list-unstyled();
    @include border-radius();


    a, span {
        position: relative;
        display: block;
        padding: $pagination-padding-y $pagination-padding-x;
        margin-left: -$pagination-border-width;
        line-height: $pagination-line-height;
        color: $pagination-color;
        background-color: $pagination-bg;
        border: $pagination-border-width solid $pagination-border-color;

        &:hover {
            z-index: 2;
            color: $pagination-hover-color;
            text-decoration: none;
            background-color: $pagination-hover-bg;
            border-color: $pagination-hover-border-color;
        }

        &:focus {
            z-index: 2;
            outline: $pagination-focus-outline;
            box-shadow: $pagination-focus-box-shadow;
        }
        // Opinionated: add "hand" cursor to non-disabled .page-link elements
        &:not(:disabled):not(.disabled) {
            cursor: pointer;
        }
    }

    li {
        &:first-child {
            a, span {
                margin-left: 0;
                @include border-left-radius($border-radius);
            }
        }

        &:last-child {
            a, span {
                @include border-right-radius($border-radius);
            }
        }

        &.active {
            a, span {
                z-index: 1;
                color: $pagination-active-color;
                background-color: $pagination-active-bg;
                border-color: $pagination-active-border-color;
            }
        }

        &.disabled {
            a, span {
                color: $pagination-disabled-color;
                pointer-events: none;
                // Opinionated: remove the "hand" cursor set previously for .page-link
                cursor: auto;
                background-color: $pagination-disabled-bg;
                border-color: $pagination-disabled-border-color;
            }
        }
    }
}

.badge-active {
    color: #1B1E24;
    background-color: #EABF2B;
}

.sticky-top-topbar {
    top: $topbar-height;
}
// fix table & col-
@mixin make-td-col($size, $columns: $grid-columns) {
    width: percentage($size / $columns);
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @for $i from 1 through $grid-columns {
        td.col#{$infix}-#{$i}, th.col#{$infix}-#{$i} {
            @include make-td-col($i, $grid-columns);
        }
    }
}

// minimal width
@include media-breakpoint-down(xs) {
    body, html {
        width: 1024px;
    }

    .app-topbar {
        right: auto;
        width: 1024px;
    }
}

.button-close {
    width: 15px;
    height: 15px;
    margin-top: -10px;
    border-radius: 10px;
    position: absolute;
    float: right;
    font-size: 10px;
    vertical-align: text-top;
    background: #6c757d;
    color: white;
}

.button-close:hover {
    color: #6c757d;
    background: #EABF2B;
    text-decoration: none;
}

.icon-unread {
    color: #EABF2B;
}

.FolderTree {
    .active {
        color: $sidebar-fg-active;
    }
}

.calendar-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: $sidebar-width;
    bottom: 0;
    margin: 1rem 1rem .5rem 1rem;
}

.fileupload-message-only {
    .dotvvm-upload {
        .dotvvm-upload-button,
        .dotvvm-upload-files {
            display: none !important;
        }
    }
}

.multiline-input {
    min-height: 200px;
}


.multiline-input-small {
    min-height: 100px;
}

.multiline-input-extra-small {
    min-height: 50px;
}

#moveFileOrFolderModal {
    .modal-body {
        max-height: 60vh;
        overflow-y: auto;
    }
}

.mh-80 {
    min-height: 300px;
    max-height: 70vh;
    overflow-y: auto;
}

.btn-fill-width {
    width: 100%;
}

.combo-smtp {
    width: 117px;
    font-size: 0.75rem;
}

.mx-5 {
    margin-left: 2rem !important;
}

.mx-6 {
    margin-left: 2.5rem;
}

.mx-7 {
    margin-left: 3rem;
}

.mx-8 {
    margin-left: 3.5rem;
}

.mx-9 {
    margin-left: 4rem;
}

.mx-10 {
    margin-left: 4.5rem;
}

/* clears the 'X' from Internet Explorer */
input::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
    display: none;
}

.autocomplete-suggestions {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #999;
    background: #FFF;
    cursor: default;
    overflow: auto;
    -webkit-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
    -moz-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
    box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
}

.autocomplete-suggestion {
    padding: 2px 5px;
    white-space: nowrap;
    overflow: hidden;
}

.autocomplete-no-suggestion {
    padding: 2px 5px;
}

.autocomplete-selected {
    background: #F0F0F0;
}

.autocomplete-suggestions strong {
    font-weight: bold;
    color: #000;
}

.autocomplete-group {
    padding: 2px 5px;
    font-weight: bold;
    font-size: 16px;
    color: #000;
    display: block;
    border-bottom: 1px solid #000;
}

.test-version {
    background-color: red;
    color:white;
    text-align: center;
}

 .btn-min-width {
     min-width: 4.15rem;
 }

.btn-file-upload:hover a {
    color: white;
    text-decoration:none;
}

.text-low-padding {
    padding:1px;
}


@import '../node_modules/perfect-scrollbar/css/perfect-scrollbar';

